var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "toolbar-border-bottom",
      attrs: {
        color: "white",
        app: "",
        dense: "",
        "clipped-right": "",
        "clipped-left": !_vm.$route.params.sid,
        height: "48px",
        flat: "",
      },
    },
    [
      !_vm.$route.params.sid
        ? _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "pl-0 flex-shrink-0" },
                [
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "router-link",
                        {
                          staticStyle: { "text-decoration": "none" },
                          attrs: { to: { name: "welcome" } },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "secondary--text ma-0 pa-0",
                              staticStyle: { "min-width": "200px" },
                            },
                            [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ hover }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center ml-1",
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "ml-2 mr-1",
                                                style: hover
                                                  ? "filter: grayscale(100%)"
                                                  : "",
                                                attrs: {
                                                  contain: "",
                                                  "max-width": "110px",
                                                  "max-height": "23px",
                                                  src: require("@/assets/Nuvolos-transparent.svg"),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1654029014
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$route.params.oid
        ? [
            !_vm.$route.params.sid
              ? _c("v-divider", {
                  staticClass: "ml-3",
                  attrs: { inset: "", vertical: "" },
                })
              : _vm._e(),
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c("BreadcrumbsLargeScreen")
              : _c("BreadcrumbsSmallScreen"),
          ]
        : _vm._e(),
      _c("v-spacer"),
      _vm.displayAPIWarning
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(_vm._b({}, "v-btn", attrs, false), on),
                          [
                            _c("v-icon", { attrs: { color: "red" } }, [
                              _vm._v("mdi-alert"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1529518884
              ),
            },
            [
              _c("span", [
                _vm._v("You are connected to " + _vm._s(_vm.customBaseURL)),
              ]),
            ]
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.mdAndUp && _vm.$auth.isAuthenticated
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-space-around flex-shrink-0",
            },
            [
              _vm.userInfo && _vm.userInfo.is_trial_user
                ? [
                    _c(
                      "v-chip",
                      { attrs: { label: "" } },
                      [
                        _vm._v(" " + _vm._s(_vm.trialText) + " "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-5",
                            attrs: {
                              elevation: "0",
                              "x-small": "",
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showNewMessageIntercom(
                                  _vm.trialMsgIntercom
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.trialButtonLabel) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.userInfo && Object.keys(_vm.userInfo).length
                ? _c(
                    "v-menu",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        "close-on-content-click": true,
                        "nudge-width": 150,
                        "offset-y": "",
                        left: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: menu }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      rounded: "",
                                                      text: "",
                                                      "e2e-toolbar-running-apps":
                                                        "",
                                                    },
                                                  },
                                                  { ...tooltip, ...menu }
                                                ),
                                                [
                                                  _vm.runningDeployments.length
                                                    ? _c(
                                                        "v-badge",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            right: "",
                                                            overlap: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "badge",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-fab-transition",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                fab: "",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                textContent:
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .runningDeployments
                                                                                      .length
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "desktop_windows"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "desktop_windows"
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Running Apps")])]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1525740352
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "secondary--text subtitle-1 mt-3 font-weight-bold",
                                    },
                                    [_vm._v("Running Applications")]
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      _vm._s(_vm.runningDeployments.length) +
                                        " applications found"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.runningDeployments.length
                            ? _c("v-divider", { staticClass: "mt-3 mb-3" })
                            : _vm._e(),
                          _c(
                            "v-list-item-group",
                            { attrs: { color: "secondary" } },
                            _vm._l(_vm.runningDeployments, function (app) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: app.aid,
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "app-open",
                                                      params: {
                                                        oid: app.oid,
                                                        sid: app.sid,
                                                        iid: app.iid,
                                                        snid: app.snid,
                                                        aid: app.aid,
                                                      },
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-list-item-avatar", [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.appIcon(app),
                                                    },
                                                  }),
                                                ]),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                      },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "secondary--text mr-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(app.name)
                                                            ),
                                                          ]
                                                        ),
                                                        app.shared === "true"
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  outlined: "",
                                                                },
                                                              },
                                                              [_vm._v("shared")]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(
                                                        _vm._s(app.description)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "StopApplication",
                                                      {
                                                        attrs: {
                                                          appName: app.name,
                                                          appId:
                                                            app.aid.toString(),
                                                          isShared:
                                                            app.shared ===
                                                            "true",
                                                          sessionId:
                                                            app.session_id,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              icon: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dark: "",
                                                                },
                                                              },
                                                              [_vm._v("stop")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-column caption",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" Space: "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-uppercase",
                                          },
                                          [_vm._v(_vm._s(app.space_long_id))]
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(" Instance: "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-uppercase",
                                          },
                                          [_vm._v(_vm._s(app.instance_long_id))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-menu",
                {
                  staticClass: "mt-1",
                  attrs: {
                    "close-on-content-click": true,
                    "nudge-width": 150,
                    "offset-y": "",
                    left: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: menu }) {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on: tooltip }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  rounded: "",
                                                  icon: "",
                                                  "e2e-toolbar-notifications":
                                                    "",
                                                },
                                              },
                                              { ...tooltip, ...menu }
                                            ),
                                            [
                                              (!_vm.runningTasksError &&
                                                _vm.runningTasks > 0) ||
                                              _vm.validRequests.length
                                                ? _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        right: "",
                                                        dot: "",
                                                        overlap: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "notifications"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                      },
                                                    },
                                                    [_vm._v("notifications")]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("User Notifications")])]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    424951004
                  ),
                },
                [
                  _c(
                    "v-list",
                    { attrs: { nav: "", dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "secondary--text subtitle-1 mt-3 font-weight-bold",
                                },
                                [_vm._v("User Notifications")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-3" }),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openTasks()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 secondary--text" },
                            [
                              _vm._v(
                                " Tasks (" +
                                  _vm._s(
                                    _vm.runningTasksError
                                      ? `error`
                                      : `${_vm.runningTasks} running`
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openRequests()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 secondary--text" },
                            [
                              _vm._v(
                                "Requests (" +
                                  _vm._s(_vm.validRequests.length) +
                                  " pending)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: menu }) {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on: tooltip }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  rounded: "",
                                                  color: "secondary",
                                                  icon: "",
                                                  "e2e-toolbar-help": "",
                                                },
                                              },
                                              { ...tooltip, ...menu }
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-lifebuoy"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Help")])]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    145872350
                  ),
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openDocs()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("help_outline"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Documentation")]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.showIntercomChat } },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-lifebuoy"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Ask for help")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "max-width": "200", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: menu }) {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on: tooltip }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  rounded: "",
                                                  icon: "",
                                                  color: "secondary",
                                                  "e2e-toolbar-account": "",
                                                },
                                              },
                                              { ...tooltip, ...menu }
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("account_circle"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Account")])]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    72736632
                  ),
                },
                [
                  _c(
                    "v-list",
                    { attrs: { nav: "", dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "secondary--text subtitle-1 mt-3 font-weight-bold",
                                },
                                [_vm._v("Account & Settings")]
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  _vm._s(
                                    _vm.userInfo ? _vm.userInfo.given_name : ""
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.userInfo
                                        ? _vm.userInfo.family_name
                                        : ""
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-3" }),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openSettings()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "subtitle-2 secondary--text",
                              attrs: { "e2e-toolbar-settings": "" },
                            },
                            [_vm._v("User Settings")]
                          ),
                        ],
                        1
                      ),
                      _vm.isAccountManager
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openResourceAndUsage()
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "subtitle-2 secondary--text",
                                  attrs: {
                                    "e2e-toolbar-account-management": "",
                                  },
                                },
                                [_vm._v("Account Management")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openMonitoring()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "subtitle-2 secondary--text",
                              attrs: { "e2e-toolbar-monitoring": "" },
                            },
                            [_vm._v("Resources")]
                          ),
                        ],
                        1
                      ),
                      _vm.userInfo && _vm.userInfo.is_sysadmin
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openSysadmin()
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "subtitle-2 secondary--text",
                                  attrs: { "e2e-toolbar-sysadmin": "" },
                                },
                                [_vm._v("Sysadmin")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "subtitle-2 secondary--text",
                              attrs: { "e2e-toolbar-logout": "" },
                            },
                            [_vm._v("Logout")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm.$auth.isAuthenticated
        ? _c(
            "div",
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "offset-overflow": "",
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "" } }, on),
                            [_c("v-icon", [_vm._v("menu")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "", focusable: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "caption secondary--text font-weight-bold",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("account_circle")]
                                  ),
                                  _vm._v(" Account & Settings "),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openSettings()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 secondary--text d-flex align-center",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("settings")]
                                          ),
                                          _vm._v(" User Settings "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.logout()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 secondary--text d-flex align-center",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("exit_to_app")]
                                          ),
                                          _vm._v(" Logout "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "caption secondary--text font-weight-bold",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("desktop_windows")]
                                  ),
                                  _vm._v(" Running Applications "),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list-item-group",
                                { attrs: { color: "secondary" } },
                                _vm._l(_vm.runningDeployments, function (app) {
                                  return _c(
                                    "v-tooltip",
                                    {
                                      key: app.aid,
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "app-open",
                                                          params: {
                                                            oid: app.oid,
                                                            sid: app.sid,
                                                            iid: app.iid,
                                                            snid: app.snid,
                                                            aid: app.aid,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 secondary--text d-flex justify-start align-center",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    width: "20",
                                                                    height:
                                                                      "20",
                                                                    src: _vm.appIcon(
                                                                      app
                                                                    ),
                                                                  },
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        app.name
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-subtitle",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                app.description
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column caption",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(" Space: "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-uppercase",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(app.space_long_id)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(" Instance: "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-uppercase",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(app.instance_long_id)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "caption secondary--text font-weight-bold",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-lifebuoy")]
                                  ),
                                  _vm._v(" Help "),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDocs()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("help_outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Documentation"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }